<template>
  <div>
    FoodBeverage
  </div>
</template>

<script>
export default {
  name: 'FoodBeverage'
}
</script>

<style>

</style>